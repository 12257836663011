/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

    var gallerySliderWithControl_container;
    var gallerySliderWithControl_0;
    var gallerySliderWithControl_1;
    var gallerySliderWithControl_2;

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages





                // Navigation
                var navMenu     = $('#nav.main__nav');
                var navOpen     = $('#nav__open');
                var navClose    = $('#nav__close');
                var ulMenu    = $('#menu-main');
                ulMenu.attr('aria-haspopup', 'true');
                ulMenu.attr('tabindex', '-1');

                $('body').focusin(function(e) {
                    if (!$.contains($(navMenu)[0], document.activeElement)) {
                        navClose.trigger('click');
                    }
                });

                // keyboard navigation
                navMenu.on('keydown', function(event){
                    // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                    // 27 = esc
                    if  (event.keyCode === 27) {
                        navClose.trigger('click');
                    }
                });

                // open navigation
                navOpen.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        ulMenu.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.addClass('open');
                        $('html, body').addClass('main__nav-opened');
                    }
                );

                // close navigation
                navClose.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        ulMenu.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.removeClass('open');
                        $('html, body').removeClass('main__nav-opened');
                    }
                );

                // toggle sub-menu
                var menuMainItems = document.querySelectorAll('.main_menu li.menu-item-has-children');

                function setAria(){
                    if ($(window).width() > 820) {

                    }

                    menuMainItems.forEach(function(item) {
                        var firstA = item.querySelector('a');
                        if (firstA.querySelector('.arrowLine') == undefined) {
                            firstA.insertAdjacentHTML('beforeend', '<span class="arrowLine">></span>');
                        }
                    });
                }
                setAria();
                $(window).resize(function() { setAria(); });

                Array.prototype.forEach.call(menuMainItems, function(el, i){
                	el.querySelector('a').addEventListener("click",  function(event){

                        // tolgo class open da tutti gli a
                        menuMainItems.forEach(function(item) {
                            item.classList.remove('open');
                        });


                		if ( this.parentNode.classList.contains('open') ) {
                            this.parentNode.classList.remove('open');
                		} else {
                			this.parentNode.classList.add('open');
                		}
                		event.preventDefault();

                		return false;
                	});

                    // focus
                    el.addEventListener("focusin", function(event){
                        this.classList.add('open');
                    });
                    el.addEventListener("focusout", function(event){
                        this.classList.remove('open');
                    });
                });

                var menuHeaderItems = document.querySelectorAll('.header_menu li.menu-item-has-children');
                Array.prototype.forEach.call(menuHeaderItems, function(el, i){

                    // focus
                    el.addEventListener("focusin", function(event){
                        this.classList.add('open');
                        //this.querySelector('a').setAttribute('aria-expanded', "true");
                    });
                    el.addEventListener("focusout", function(event){
                        this.classList.remove('open');
                        //this.querySelector('a').setAttribute('aria-expanded', "false");
                    });

                    // mouse
                    el.addEventListener("mouseover", function(event){
                        this.classList.add('open');
                        //this.querySelector('a').setAttribute('aria-expanded', "true");
                    });
                    el.addEventListener("mouseout", function(event){
                        this.classList.remove('open');
                        //this.querySelector('a').setAttribute('aria-expanded', "false");
                    });

                    // click
                	el.querySelector('a').addEventListener("click",  function(event){
                		if ( this.parentNode.classList.contains('open') ) {
                            this.parentNode.classList.remove('open');
                			//this.setAttribute('aria-expanded', "false");
                		} else {
                			this.parentNode.classList.add('open');
                            //this.setAttribute('aria-expanded', "true");
                		}
                		event.preventDefault();
                		return false;
                	});
                });





                // AOS - animate on scroll
                // setting
                AOS.init({
                    offset: 120,
                    once: true,
                });

                document.querySelectorAll('img')
                    .forEach(function (img) {
                        img.addEventListener('load', function() {
                            AOS.refresh();
                        });
                    });



                // Slider
                var gallerySlider = $('.gallery');

                gallerySlider.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: true,
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    responsive: [
                        {
                            breakpoint: 1080,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                          }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


                var gallerySliderSingle = $('.gallery--single');

                gallerySliderSingle.slick({
                    arrows: false,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });

                gallerySliderWithControl_container = $('.gallery--withcontrol_container');
                gallerySliderWithControl_0 = $('.gallery--withcontrol_0');
                gallerySliderWithControl_1 = $('.gallery--withcontrol_1');
                gallerySliderWithControl_2 = $('.gallery--withcontrol_2');

                gallerySliderWithControl_container = $('.gallery--withcontrol_container').slick({
                    arrows: false,
                    autoplay: false,
                    infinite: false,
                    draggable: false,
                    slidesToShow: 1
                });
                var paramsGallery = {
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: false,
                    pauseOnHover: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 0,
                };
                gallerySliderWithControl_0 = $('.gallery--withcontrol_0').slick(paramsGallery);
                gallerySliderWithControl_1 = $('.gallery--withcontrol_1').slick(paramsGallery);
                gallerySliderWithControl_2 = $('.gallery--withcontrol_2').slick(paramsGallery);
                gallerySliderWithControl_1.slick('slickPause');
                gallerySliderWithControl_2.slick('slickPause');


                // Magnific Popup
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                        function(item) {
                            return item.el.find('img').attr('alt');
                        }
                    }
                });

                // Video
                var magPopVideo = $('.magnificpopup--video');
                magPopVideo.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">'+
                                '<div class="mfp-close"></div>'+
                                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                        patterns: {
                            youtube: {
                                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                                id: 'embed/', // String that splits URL in a two parts, second part should be %id%
                                // Or null - full URL will be returned
                                // Or a function that should return %id%, for example:
                                // id: function(url) { return 'parsed id'; }

                                src: '//www.youtube.com/embed/%id%' // URL that will be set as a source for iframe.
                            }
                        },
                    }
                });

                // Gallery
                var magPopGallery = $('.magnificpopup--gallery');
                magPopGallery.each(function() { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function(item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });

                // Gallery
                var wysiwygGallery = $('.text--wysiwyg .gallery');
                wysiwygGallery.each(function() { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function(item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });

                // Arrow to top
                var top_arrow = $('.top_arrow');
                var htmlBody = $('html, body');
                window.addEventListener('scroll', function() {
                    var distanceTop = htmlBody.scrollTop();
                    if (distanceTop > 100) {
                        top_arrow.fadeIn();
                    } else {
                        top_arrow.fadeOut();
                    }
                });

                // aggiunta classe a header fixed quando si scrolla la pagina
                window.addEventListener("scroll", function() {
                    var header = document.querySelector("header");
                    var stickyTop = window.scrollY;
                    var mainMenu = document.querySelector('#nav');

                    if (stickyTop > 50) {
                        header.classList.add('sticky');
                    } else {
                        if( header.classList.contains('sticky') ){
                            header.classList.remove('sticky');
                        }
                    }
                });


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },



        // Page template Homepage
        'page_template_homepage': {
            init: function() {

                //var path3 = getBBox(document.querySelector("#mask_intro_path5"));
                //console.log(path3);

                // firefox fix
                var userAgentString = navigator.userAgent;
                var firefoxAgent = userAgentString.indexOf("Firefox") > -1;
                console.log(firefoxAgent);

                // JavaScript to be fired
                gsap.set("#mask_intro_path1", { y: "+=0.18", display:"none" }); // ani 2
                gsap.set("#mask_intro_path2", { y: "+=0.18", display:"none" }); // ani 2
                gsap.set("#mask_intro_path3", { x: "+=0.18" }); // ani 1
                gsap.set("#mask_intro_path4", { x: "+=0.16", display:"none" }); // ani 3
                gsap.set("#mask_intro_path5", { x: "-=0.18" }); // ani 1
                gsap.set("#mask_intro_path6", { y: "-=0.19", display:"none" }); // ani 2
                gsap.set("#mask_intro_path7", { y: "-=0.19", display:"none" }); // ani 2
                gsap.set("#mask_intro_path8", { x: "-=0.16", display:"none" }); // ani 3
                var tl2 = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    paused: true,
                    delay: 2,
                });
                var tl3 = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    paused: true,
                    delay: 0.2
                });
                var tl4 = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    paused: true,
                    delay: 0.4
                });
                var tl5 = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    paused: true,
                    delay: 0.6
                });
                var tl6 = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    paused: true,
                    delay: 0.8,
                });
                var tl7 = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    paused: true,
                    delay: 1
                });
                var tl8 = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    paused: true,
                    delay: 1.2,
                });
                var tl9 = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    paused: true,
                    delay: 1.4,
                });
                // animazione immagine
                var tlImg = gsap.timeline({
                    repeat: -1,
                    paused: true,
                });
                

                var tl = gsap.timeline({
                    delay: 2,
                    onComplete: function() {
                        tl2.play();
                        tl3.play();
                        tl4.play();
                        tl5.play();
                        tl6.play();
                        tl7.play();
                        tl8.play();
                        tl9.play();
                        if(!firefoxAgent) {
                            tlImg.play();
                        }
                    }
                });
                tl.add('start')
                    .to("#mask_intro_path3", {duration: 1, x: "-=0.18", ease: "elastic.out(1, 0.3)"}, 'start')
                    .to("#mask_intro_path5", {duration: 1, x: "+=0.18", ease: "elastic.out(1, 0.3)"}, 'start')
                    .add('second')
                    .to("#mask_intro_path1", {duration: 1, y: "-=0.19", display:"block", transformOrigin: "center center", ease: "elastic.out(1, 0.3)"}, 'second-=0.3')
                    .to("#mask_intro_path2", {duration: 1, y: "-=0.19", display:"block", transformOrigin: "center center", ease: "elastic.out(1, 0.3)"}, 'second-=0.3')
                    .to("#mask_intro_path6", {duration: 1, y: "+=0.19", display:"block", transformOrigin: "center center", ease: "elastic.out(1, 0.3)"}, 'second-=0.3')
                    .to("#mask_intro_path7", {duration: 1, y: "+=0.19", display:"block", transformOrigin: "center center", ease: "elastic.out(1, 0.3)"}, 'second-=0.3')
                    .add('third')
                    .to("#mask_intro_path4", {duration: 1, x: "-=0.16", display:"block", transformOrigin: "center center", ease: "elastic.out(1, 0.3)"}, 'third-=0.5')
                    .to("#mask_intro_path8", {duration: 1, x: "+=0.16", display:"block", transformOrigin: "center center", ease: "elastic.out(1, 0.3)"}, 'third-=0.5');
                    
                tl2.from("#mask_intro_path3", { scaleX:1.25, scaleY:1.1, duration: 2, transformOrigin: "center center", ease: "elastic.out(1, 0.3)",  immediateRender:false });
                tl3.from("#mask_intro_path5", { scaleX:1.25, scaleY:1.1, duration: 2, transformOrigin: "center center", ease: "elastic.out(1, 0.3)",  immediateRender:false });
                tl4.from("#mask_intro_path1", { scaleX:0.65, scaleY:0.70, duration: 2, transformOrigin: "center center", ease: "elastic.out(1, 0.3)",  immediateRender:false });
                tl5.from("#mask_intro_path2", { scaleX:0.85, scaleY:0.75, duration: 2, transformOrigin: "center center", ease: "elastic.out(1, 0.3)",  immediateRender:false });
                tl6.from("#mask_intro_path6", { scaleX:0.85, scaleY:0.75, duration: 2, transformOrigin: "center center", ease: "elastic.out(1, 0.3)",  immediateRender:false });
                tl7.from("#mask_intro_path7", { scaleX:0.65, scaleY:0.70, duration: 2, transformOrigin: "center center", ease: "elastic.out(1, 0.3)",  immediateRender:false });
                tl8.from("#mask_intro_path4", { scaleX:0.85, scaleY:0.90, duration: 2, transformOrigin: "center center", ease: "elastic.out(1, 0.3)",  immediateRender:false });
                tl9.from("#mask_intro_path8", { scaleX:0.75, scaleY:0.80, duration: 2, transformOrigin: "center center", ease: "elastic.out(1, 0.3)",  immediateRender:false });

                tlImg.add('start')
                    .to("#mask_intro", { x: "-=10", y: "+=20", rotation: 0.1, duration: 3, immediateRender:false }, 'start')
                    .to('.home__animazione_1 .image', { x: "+=10", y: "-=20", rotation: -0.1, duration: 3, immediateRender:false }, 'start')
                    .add('second')
                    .to("#mask_intro", { x: "+=20", y: "-=10", rotation: 0.1, duration: 3, immediateRender:false }, 'second')
                    .to('.home__animazione_1 .image', { x: "-=20", y: "+=10", rotation: -0.1, duration: 3, immediateRender:false }, 'second')
                    .add('third')
                    .to("#mask_intro", { x: "-=20", y: "+=10", rotation: 0.1, duration: 3, immediateRender:false }, 'third')
                    .to('.home__animazione_1 .image', { x: "+=20", y: "-=10", rotation: -0.1, duration: 3, immediateRender:false }, 'third')
                    .add('fourth')
                    .to("#mask_intro", { x: "+=10", y: "-=20", rotation: 0.1, duration: 3, immediateRender:false }, 'fourth')
                    .to('.home__animazione_1 .image', { x: "-=10", y: "+=20", rotation: -0.1, duration: 3, immediateRender:false }, 'fourth');

                // animazione scroll down
                var scrollAni = gsap.timeline({ repeat: -1, yoyo:true });
                scrollAni.add('start')
                .from('#scroll_icon_pallino', { duration: 0.5, y: "+=15", ease: "expo.out" }, 'start')
                .from('#scroll_icon_scritta', { duration: 0.8, scaleX: 1.8, fill: "#C3C320", transformOrigin: "center center", ease: "expo.out"}, 'start');

                if ($(window).width() < 820) {
                    scrollAni.pause();
                }
                $(window).resize(function() { 
                    if ($(window).width() < 820) {
                        scrollAni.pause();
                    }
                 });

            

                function getBBox(element, withoutTransforms, toElement) {
  
                    var svg = element.ownerSVGElement;
                    
                    if (!svg) {
                      return { x: 0, y: 0, cx: 0, cy: 0, width: 0, height: 0 };
                    }
                    
                    var r = element.getBBox(); 
                    
                    if (withoutTransforms) {
                      return {
                        x: r.x,
                        y: r.y,
                        width: r.width,
                        height: r.height,        
                        cx: r.x + r.width / 2,
                        cy: r.y + r.height / 2
                      };
                    }
                    
                    var p = svg.createSVGPoint(); 
                        
                    var matrix = (toElement || svg).getScreenCTM().inverse().multiply(element.getScreenCTM()); 
                  
                    p.x = r.x;
                    p.y = r.y;
                    var a = p.matrixTransform(matrix);
                  
                    p.x = r.x + r.width;
                    p.y = r.y;
                    var b = p.matrixTransform(matrix);
                  
                    p.x = r.x + r.width;
                    p.y = r.y + r.height;
                    var c = p.matrixTransform(matrix);
                  
                    p.x = r.x;
                    p.y = r.y + r.height;
                    var d = p.matrixTransform(matrix);
                  
                    var minX = Math.min(a.x, b.x, c.x, d.x);
                    var maxX = Math.max(a.x, b.x, c.x, d.x);
                    var minY = Math.min(a.y, b.y, c.y, d.y);
                    var maxY = Math.max(a.y, b.y, c.y, d.y);
                      
                    var width = maxX - minX;
                    var height = maxY - minY;
                    
                    return {
                      x: minX,
                      y: minY,
                      width: width,
                      height: height,        
                      cx: minX + width / 2,
                      cy: minY + height / 2
                    };
                }

            },
            finalize: function() {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

        // Page template Servizi
        'page_template_servizi': {
            init: function() {
                var labelsSlide = document.querySelectorAll('.slideshow_servizi .title');
                var targetsDiv = document.querySelectorAll('.slideshow_servizi .text');
                var targetsSlide = document.querySelectorAll('.slideshow_servizi .slick-slide');

                labelsSlide.forEach(function(item) {
                    item.addEventListener("click",  function(event) {
                        var labelData = event.target.getAttribute('data-label');
                        var labelId = labelData.split('_')[1];
                        var targetLabel = document.querySelector('#target_'+labelId);
                        
                        labelsSlide.forEach(function(item) {
                            if (item.classList.contains('active')) {
                                item.classList.remove('active');
                            }
                        });

                        targetsDiv.forEach(function(item) {
                            if (item.classList.contains('active')) {
                                item.classList.remove('active');
                            }
                        });

                        targetsSlide.forEach(function(item) {
                            if (item.classList.contains('slick-active')) {
                                item.classList.remove('slick-active');
                            }
                        });

                        targetLabel.classList.add('active');
                        event.target.classList.add('active');

                        // fermo gli altri slideshow e faccio partire quello corrente
                        switch (labelId) {
                            case '0':
                                gallerySliderWithControl_0.slick('slickPlay');
                                gallerySliderWithControl_1.slick('slickPause');
                                gallerySliderWithControl_2.slick('slickPause');
                                break;

                            case '1':
                                gallerySliderWithControl_0.slick('slickPause');
                                gallerySliderWithControl_1.slick('slickPlay');
                                gallerySliderWithControl_2.slick('slickPause');
                                break;

                            case '2':
                                gallerySliderWithControl_0.slick('slickPause');
                                gallerySliderWithControl_1.slick('slickPause');
                                gallerySliderWithControl_2.slick('slickPlay');
                                break;
                        
                            default:
                                break;
                        }

                        gallerySliderWithControl_container.slick('slickGoTo', labelId);
                    });
                });
            },
            finalize: function() {
                // JavaScript to be fired, after page specific JS is fired
            }
        },


    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
